<template>
<section class="section-narrow text-black">
    <div class="section-content">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-10">
                    <h1>{{$t('rates.heading')}}</h1>
                    <p>{{$t('rates.subtitle', { account: selectedAccount.client_description } )}}</p>
                </div>
            </div>
            <div class="mb-4">
                <b-btn
                    class="mr-2 mb-2"
                    :variant="($router.currentRoute.name === 'Rates') ? 'cyrrus-yellow' : 'white'"
                    :to="{ name: 'Rates' }"
                >
                    <span class="iconify" data-icon="mdi:table"></span> {{$t('rates.ratesTable')}}
                </b-btn>
                <b-btn
                    class="mr-2 mb-2"
                    :variant="($router.currentRoute.name === 'RatesDashboard') ? 'cyrrus-yellow' : 'cyrrus-gray-light'"
                    :to="{ name: 'RatesDashboard' }"
                >
                    <span class="iconify" data-icon="mdi:chart-line"></span> {{$t('rates.ratesDashboard')}}
                </b-btn>
            </div>
            <transition
                enter-active-class="animated fadeIn faster"
                leave-active-class="animated fadeOut faster"
                mode="out-in"
            >
                <router-view></router-view>
            </transition>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: 'RatesPage',
    computed: {
        selectedAccount: {
            get () {
                return this.$store.state.selectedAccount
            },
            set (account) {
                this.$store.commit('selectAccount', account)
            }
        }
    }
}
</script>
